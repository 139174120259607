.about {
    background-color: #F0F0F0;
}

.about__title {
    text-align: center;
    color: black;
    //padding-top: 80px;
    //margin-bottom: 130px;
}

.about__content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 150px;
}

.about__text {
    font-family: 'OpenSans Regular', Arial, serif;
    font-size: 21px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.3;
    margin-right: 50px;
    flex-grow: 1;
}

.about__imagebox {
    position: relative;
    width: 533px;
    height: 767px;
    overflow: hidden;
    flex-grow: 2;
    flex-shrink: 0;
}

.img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 533px;
    height: 607px;
    object-fit: cover;
    object-position: center center;
}

@media (max-width: 1200px) {
    .about__imagebox {
        width: 440px;
        height: 591px;
    }

    .img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 533px;
        height: 607px;
        object-position: 0 -20px;
    }
}

@media (max-width: 1000px) {
    .about__title {
        margin-bottom: 50px;
    }
    .about__content {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 50px;
    }

    .about__text {
        order: 2;
        margin: 0 15px 0 15px;
    }

    .about__imagebox {
        width: 350px;
        height: 350px;
        order: 1;
        border-radius: 50%;
        margin-bottom: 50px;
    }

    .img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 533px;
        height: 607px;
        object-position: 0 -20px;
    }
}

@media (max-width: 400px) {
    .about__imagebox {
        position: relative;
        width: 300px;
        height: 300px;
        overflow: hidden;
        flex-grow: 2;
        flex-shrink: 0;
    }

    .img {
        position: absolute;
        top: 64%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 533px;
        height: 607px;
        object-fit: cover;
        object-position: center top;
    }
}