@font-face {
    font-family: 'Montserrat SemiBold';
    src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf')  format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Regular';
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf')  format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Medium';
    src: url('../fonts/Montserrat/Montserrat-Medium.ttf')  format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans Regular';
    src: url('../fonts/Open Sans/OpenSans-Regular.ttf')  format('truetype');
    font-weight: 400;
    font-style: normal;
}