@charset "UTF-8";

/**
 * Основные стили
 **/

html {
	font-size: 16px;
}

body {
	font-family: 'Roboto', 'Arial', sans-serif;
	overflow-wrap: break-word;
	word-wrap: break-word;
}

body._lock {
	overflow: hidden;
}

.container {
	@include min-max(320px, 100%, 0);
}

.wrap {
	@include min-max(320px, 1200px, 15px);
}


section {
	padding-top: 50px;
	padding-bottom: 50px;
}

.footer {
	padding-top: 24px;
	padding-bottom: 24px;
}