.problems {


  /* temp style */
  padding: 50px 0;

}

.section__title {
  text-align: center;

  //font-family: 'Montserrat Regular', Arial, serif;
  //font-size: 45px;
  //font-style: normal;
  //font-weight: normal;
  //line-height: 1;

  padding: 0;
  margin: 0 0 80px 0;
}

.problems__content {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  row-gap: 50px;
  column-gap: 25px;
}

.problems__item {
  display: flex;
  flex-direction: row;
  //gap: 12px;
}

.problems__icon {
  margin-bottom: 35px;
  img {
    height: 150px;
    width: 150px;
  }
}

.problems__desk {
  margin-left: 45px;
}

.problems__subtitle {
  font-family: 'Montserrat Regular', Arial, serif;
  font-size: 35px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;


  padding: 0;
  margin: 0 0 35px 0;
}

.problems__text {
  font-family: 'OpenSans Regular', Arial, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@media (max-width: 900px) {
  .problems__content {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    row-gap: 50px;
    column-gap: 25px;
  }
}

@media (max-width: 650px) {
  .problems__item {
    flex-direction: column;
  }
  .problems__icon {
    align-self: center;
  }

  .problems__desk {
    margin-left: 0;
  }
  .problems__subtitle {
    text-align: center;
  }
}