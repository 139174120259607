.contacts {
    background-image: url("../img/screen6/bg.webp");
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.contacts::after {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: .25;
}

.contacts__title {
    color: white;
    position: relative;
    z-index: 10;
    //padding-top: 60px;
    margin-bottom: 60px;
}

.contacts__content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
}

.contacts__links {
    //flex-basis: 45%;
    align-self: stretch;
}

.links {
    height: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.links__item {
    position: relative;
    margin-bottom: 17px;

    a {
        font-family: 'Montserrat Medium', Arial, serif;
        font-size: 35px;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        color: white;
        text-decoration: none;
        margin-left: 80px;
    }
}

.links__item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
}

.vk:before {
    content: '';
    background-image: url("../img/screen6/icons/vk.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 33px;
}

.email:before {
    content: '';
    background-image: url("../img/screen6/icons/email.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
}

.instagram:before {
    content: '';
    background-image: url("../img/screen6/icons/instagram.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
}

.fb:before {
    content: '';
    background-image: url("../img/screen6/icons/fb.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;

}

.whatsapp:before {
    content: '';
    background-image: url("../img/screen6/icons/whatsapp.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;

}

.contacts__form {
    display: flex;
    flex-direction: column;
    //flex-basis: 45%;

    label {
        @include visually-hidden;
    }

    input, textarea {
        background-color: transparent;
        border: 1px solid transparent;
        border-bottom: 2px solid white;
        padding: 10px 20px 10px 20px;
        margin-bottom: 12px;

        font-family: 'Montserrat Medium', Arial, serif;
        font-size: 25px;
        font-style: normal;
        font-weight: normal;
        line-height: 1;

        color: white;
        outline: none;
    }

    input::placeholder, textarea::placeholder {
        color: rgba(255,255,255,1);
    }

    input::-moz-placeholder, textarea::-moz-placeholder {
        color: rgba(255,255,255,1);
    }

    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
        color: rgba(255,255,255,1);
    }
}

.contacts__form_button {
    margin: 12px auto 12px 0;
    cursor: pointer;
}

.theme,
#theme {
    @include visually-hidden;
}

@media (max-width: 1000px) {
    .email a {
        display: inline-block;
        width: 50%;
    }
}

@media (max-width: 805px) {

    .email a {
        display: inline-block;
        width: 100%;
    }

    .contacts__content {

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 20px;
    }

    .links {
        margin-bottom: 32px;
    }

    .contacts__links {
        margin: 0 auto 15px auto;
    }

    .links__item {
        position: relative;
        margin-bottom: 37px;

        a {
            font-family: 'Montserrat Medium', Arial, serif;
            font-size: 25px;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            color: white;
            text-decoration: none;
            margin-left: 52px;
        }
    }

    .links__item:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 35px;
        height: 35px;
    }

    .vk:before {
        content: '';
        background-repeat: no-repeat;
        background-size: cover;
        width: 35px;
        height: 22px;
    }
}

@media (max-width: 400px) {

    .contacts__form {
        display: flex;
        flex-direction: column;

        input, textarea {
            background-color: transparent;
            border: 1px solid transparent;
            border-bottom: 2px solid white;
            padding: 7px 10px 7px 10px;
            margin-bottom: 12px;

            font-family: 'Montserrat Medium', Arial, serif;
            font-size: 23px;
            font-style: normal;
            font-weight: normal;
            line-height: 1;

            color: white;
            outline: none;
        }
    }

    .contacts__form_button {
        margin: 12px auto 12px 0;
        cursor: pointer;
    }

}

@media (max-width: 320px) {

    .links__item {
        position: relative;
        margin-bottom: 17px;

        a {
            font-family: 'Montserrat Medium', Arial, serif;
            font-size: 20px;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            color: white;
            text-decoration: none;
            margin-left: 52px;
        }
    }

    .links__item:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
    }

    .vk:before {
        content: '';
        background-repeat: no-repeat;
        background-size: cover;
        width: 35px;
        height: 22px;
    }


    .contacts__form {
        display: flex;
        flex-direction: column;

        input, textarea {
            background-color: transparent;
            border: 1px solid transparent;
            border-bottom: 2px solid white;
            padding: 7px 10px 7px 10px;
            margin-bottom: 12px;

            font-family: 'Montserrat Medium', Arial, serif;
            font-size: 16px;
            font-style: normal;
            font-weight: normal;
            line-height: 1;

            color: white;
            outline: none;
        }
    }

    .contacts__form_button {
        margin: 12px auto 12px 0;
        cursor: pointer;
    }

}
