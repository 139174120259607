.services {
    background-color: white;
}

.services__title {
    //padding-top: 82px;
}

.services__content {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.services__spoiler {
    display: flex;
    flex-direction: column;
    margin-bottom: 38px;
    position: relative;
}

/* спойлер скрыт по умолчанию */
.services__spoiler {
    input, .spoiler__content {
        @include visually-hidden;
    }

    ul {
        margin: 0;
        padding-left: 35px;
        li {
            list-style-type: upper-roman;
            margin-bottom: 12px;
            line-height: 1.2;
        }
    }

}

/* если .services__spoiler имеет флаг :checked, .spoiler__content должен быть показан */
.services__spoiler :checked ~ .spoiler__content {
    @include show;
}

/* Закрытый спойлер */
.services__spoiler label::before {
    content: '';
    margin-right: 5px;
    margin-left: 5px;
    position: absolute;
    left: 0;
    background-image: url("../img/screen5/icon_plus.svg");
    background-size: 35px 35px;
    width: 35px;
    height: 35px;
}
/* Открытый спойлер */
.services__spoiler :checked + label::before {
    content: '';
    position: absolute;
    left: 0;
    transform: rotate(45deg);
}

.services__label {
    font-family: 'Montserrat Medium', Arial, serif;
    font-size: 35px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;

    padding-bottom: 7px;
    padding-left: 50px;
    border-bottom: 2px solid black;
    cursor: pointer;
}

.spoiler__content {
    font-family: 'OpenSans Regular', Arial, serif;
    font-size: 21px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.3;
    p {
        //text-indent: 50px;
    }
}