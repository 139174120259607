footer {
    background-color: black;
    text-align: center;
    font-family: 'Montserrat Medium', Arial, serif;
    font-size: 21px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.2;

    color: white;

}

.footer__content {
    padding: 3px 0 3px 0;
    p {
        margin: 4px;
        padding: 0;
    }
}