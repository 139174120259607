.advantages {
    background-color: #F0F0F0;
}

.advantages__title {
    //padding-top: 83px;
    margin-bottom: 120px;
}

.advantages__content {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: auto auto;
    column-gap: 35px;
    row-gap: 55px;
    padding-bottom: 78px;
}

.advantages__item {
    //border: 1px solid black;
    padding: 26px 15px 26px 15px;
}

.advanteges__box {
    //margin: 100px;
    //border: 1px solid blueviolet;
    display: block;
    position: relative;
    overflow:hidden;
    width: 300px;
    height: 160px;
    img {
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        width:300px;
        //height:300px;
        object-fit:cover;
    }
}

.item_title {
    font-family: 'Montserrat Regular', Arial, serif;
    font-size: 35px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
}

.item_text {
    font-family: 'OpenSans Regular', Arial, serif;
    font-size: 25px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
}

@media (max-width: 900px) {
    .advantages__item {
        margin: 0 auto;
    }
    .advantages__content {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        column-gap: 30px;
        row-gap: 25px;
        padding-bottom: 28px;
    }
}

@media (max-width: 700px) {
    .advantages__content {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        column-gap: 30px;
        row-gap: 25px;
        padding-bottom: 28px;
    }
}