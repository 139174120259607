h2, h3 {
    margin: 0;
    padding: 0;
}


.section__title {
    font-family: 'Montserrat Medium', Arial, serif;
    font-size: 45px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    margin-bottom: 50px;
    text-align: center;
}