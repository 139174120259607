.header {
    padding-top: 300px;
    padding-bottom: 200px;
    background-image: url("../img/screen1/bg.webp");
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
}

.header::after {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: .25;
}

.header__content {
    //padding-top: 300px;
    //padding-bottom: 200px;
    position: relative;
    z-index: 10;
}

.header__title {
    font-family: 'Montserrat SemiBold', Arial, serif;
    font-size: 55px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-align: center;

    color: white;

    margin-bottom: 55px;
}

.header__text {
    font-family: 'Montserrat Regular', Arial, serif;
    font-size: 44px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4;
    text-align: center;

    width: 900px;
    margin: 0 auto 85px auto;

    color: white;
}

.header__button {
    display: block;
    margin: 0 auto 0 auto;
    padding: 25px 100px 20px 100px;
    background-color: white;
    border-radius: 7px;
    cursor: pointer;
    font-family: 'Montserrat Regular', Arial, serif;
    font-size: 21px;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-align: center;
}

.button {
    //background: linear-gradient(180deg, #FDDE76 0%, #FFBB01 100%);
    background: linear-gradient(180deg, rgb(255, 255, 255) 14%, rgb(219, 219, 219) 78%);
    border-radius: 5px;
    border: none;
    box-shadow: 0px 4px 0px #7d7b7b;
    color: #2E2E30;
}

.button:hover {
    box-shadow: 0px 6px 0px #7d7b7b;
    cursor: pointer;
    outline: none;
    transform: translateY(-2px);
}

.button:active {
    //background: linear-gradient(180deg, #FFBB01 0%, #FDDE76 100%);
    background: linear-gradient(-180deg, rgb(255, 255, 255) 14%, rgb(219, 219, 219) 78%);
    box-shadow: 0px 2px 0px #7d7b7b;
    transform: translateY(2px);
    /* color: #fff;
    text-shadow: 0 -1px 0 #000; */
}

.button:focus {
    outline: none;
}


@media (max-width: 900px) {

    .header__text {
        width: auto;
    }


}

@media (max-width: 800px) {

    .header {
        padding-top: 150px;
        padding-bottom: 190px;
    }

}

@media (max-width: 650px) {

    .header {
        padding-top: 150px;
        padding-bottom: 190px;
    }
    .header__title {
        font-size: 45px;
        margin-bottom: 40px;
    }

    .header__text {
        font-size: 34px;
        line-height: 1.4;
        margin: 0 auto 90px auto;
    }

}

@media (max-width: 400px) {
    .header__button {
        display: block;
        margin: 0 auto 0 auto;
        padding: 20px 50px 20px 50px;
        background-color: white;
        border-radius: 7px;

        font-family: 'Montserrat Regular', Arial, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-align: center;
    }
}
