.docs {
    background-image: url("../img/screen3/bg3.webp");
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

}

.docs::after {
    content: '';
    z-index: 5;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: .25;
}

.docs__content {
    //padding-top: 300px;
    //padding-bottom: 200px;
    position: relative;
    z-index: 10;
}

.docs__title {
    color: white;
    text-align: center;
    padding-top: 50px;
    //margin-bottom: 166px;
}

.docs__item {
    height: 600px;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 600px;
        object-fit: contain;
        object-position: center center;
    }
}

.docs-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px 0 50px 0;
    gap: 13px;
}


.docs__bullet {
    height: 15px;
    width: 15px;
    background-color: gray;
    border-radius: 50%;
    cursor: pointer;
    border: 3px solid transparent;
}

.docs__active {
    background-color: azure;
    border: 3px solid white;
}

//.docs__imagebox {
//    position: relative;
//    width: 700px;
//    height: 500px;
//    overflow: hidden;
//    flex-grow: 2;
//    flex-shrink: 0;
//    img {
//        position: absolute;
//        top: 50%;
//        left: 50%;
//        transform: translate(-50%, -50%);
//        width: 700px;
//        height: 500px;
//        object-fit: cover;
//        object-position: center center;
//    }
//}

@media (max-width: 767px) {
    .docs__item {
        height: 500px;
        width: 500px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 500px;
            width: 500px;
            object-fit: contain;
            object-position: center center;
        }
    }
}

@media (max-width: 500px) {
    .docs__item {
        height: 450px;
        width: 450px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 450px;
            width: 450px;
            object-fit: contain;
            object-position: center center;
        }
    }
}

@media (max-width: 420px) {
    .docs__item {
        height: 380px;
        width: 380px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 380px;
            width: 380px;
            object-fit: contain;
            object-position: center center;
        }
    }
}

@media (max-width: 320px) {
    .docs__item {
        height: 280px;
        width: 280px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 280px;
            width: 280px;
            object-fit: contain;
            object-position: center center;
        }
    }
}