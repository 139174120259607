.nav {
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 11px;
    padding-bottom: 11px;
    z-index: 20;
}

.logo {
    //background-color: red;
    //width: 100px;
}

.nav__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav__list {
    display: flex;
    flex-direction: row;
    flex-basis: 60%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    gap: 24px;
}

.nav__item {
    font-family: 'OpenSans Regular', serif;
    list-style-type: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    text-transform: capitalize;

    a {
        color: white;
        text-decoration: none;
        //border-bottom: 1px solid transparent;
        //transition-property: border-bottom;
        //transition-duration: 500ms;
    }

    a:hover {
        //border-bottom: 1px solid white;
    }
}

.menu__icon {
    display: none;
}

@media (max-width: 767px) {
    .nav {
        background: black;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        min-height: 20px;

        padding-top: 11px;
        padding-bottom: 11px;
        z-index: 20;
    }
    .nav__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        padding-top: 55px;

        position: fixed;
        top: 0;
        left: -100%;
        //flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .9);
        //align-items: center;
        transition: left 0.3s ease 0s;
        overflow: auto;

        .nav__item {
            margin-bottom: 25px;
        }



    }
    .nav__list._active:first-child {
        margin-top: 35px;
    }

    .menu__icon {
        z-index: 25;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        transform: translate(12px, 12px);
        width: 30px;
        height: 18px;
        cursor: pointer;
    }

    .menu__icon span,
    .menu__icon::before,
    .menu__icon::after {
        position: absolute;
        height: 2px;
        width: 100%;
        transition: all 0.3s ease 0s;
        background-color: #fff;
    }

    .menu__icon::before,
    .menu__icon::after {
        content: '';
    }

    .menu__icon::before {
        top: 0;
    }

    .menu__icon span {
        top: 50%;
        transform: scale(1) translate(0px, -50%);
    }

    .menu__icon::after {
        bottom: 0;
    }

    .menu__icon._active {

    }

    .menu__icon._active::before {
        top: 50%;
        transform: rotate(-45deg) translate(0px, -50%);
    }

    .menu__icon._active span {
        transform: scale(0) translate(0px, -50%);
    }

    .menu__icon._active::after {
        bottom: 50%;
        transform: rotate(45deg) translate(0px, 50%);
    }

    .nav__list {

    }

    ._active {
        left: 0;
    }
}


@media (max-width: 500px) {
    ._active {
        left: 0;
    }
    //.nav {
    //    position: fixed;
    //    top: 0;
    //    left: 0;
    //}
    .nav__list {
        flex-direction: column;
        //justify-content: center;
        align-items: center;
        padding-top: 55px;
    }

    .nav__item {
        margin-bottom: 12px;

    }

}